<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("Dossiers") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
      </b-button>
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchFunc="alldossier"
          :showFiltre="true"
          :searchText="$t('SEARCH')"
          :filtre="{}"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <div class="filtre">
        <p class="table-count">{{ getTotalRowDossier }} {{ $t("Dossiers") }}</p>
        <div class="productTrie" v-if="current_client.lib != null">
          {{ current_client.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("NAME") }}
              </th>
              <th>
                {{ $t("DELEGATAIRE") }}
              </th>
              <th>
                {{ $t("CUSTOMER") }}
              </th>
              <th>
                {{ $t("DATE") }}
              </th>
              <th>
                {{ $t("STATUT") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAlldossiers"
              :key="i"
              @click="handleDossier(data)"
            >
              <td aria-colindex="1" role="cell">
                <svg
                  v-if="data.lock"
                  class="lock"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M6 22q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.588 1.413T18 22H6Zm6-5q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Z"
                  />
                </svg>
                {{ data.name }}
              </td>
              <td aria-colindex="2" role="cell">
                {{ data.delegataire.name }}
              </td>
              <td aria-colindex="3" role="cell">
                {{ data.client.nom }} {{ data.client.prenom }}
              </td>
              <td aria-colindex="4" role="cell">
                {{ moment(data.created_at).format("DD-MM-YYYY") }}
              </td>
              <td aria-colindex="5" role="cell">
                <span v-if="data.status == 'created'" class="created">
                  {{ $t("CREATED") }}
                </span>
                <span v-else-if="data.status == 'processing'" class="encours">
                  {{ $t("En cours de traitement") }}
                </span>
                <span v-else-if="data.status == 'done'" class="end">
                  {{ $t("OCR_DONE") }}
                </span>
                <span class="none" v-else>
                  {{ $t("OCR_UNDONE") }}
                </span>
              </td>
              <td aria-colindex="6" role="cell" class="action_col">
                <b-button
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                  v-if="!data.lock"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                  v-if="!data.lock"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal id="detailModal" ref="detailModal" size="xl">
        <template #modal-header="{ close }" v-if="dossierDetail">
          <h5>{{ dossierDetail.name }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <div v-if="dossierDetail" class="modal-test">
          <div>
            <div class="cours">
              <p
                v-if="dossierDetail.status == 'created'"
                style="background: #d0cccc"
                class="created"
              >
                {{ $t("CREATED") }}
              </p>
              <p
                v-else-if="dossierDetail.status == 'processing'"
                style="background: #ffb300"
              >
                {{ $t("En cours de traitement") }}
              </p>
              <p
                v-else-if="dossierDetail.status == 'done'"
                style="background: #3dd598"
              >
                {{ $t("OCR_DONE") }}
              </p>
              <p v-else style="background: #e4261b">
                {{ $t("OCR_UNDONE") }}
              </p>
            </div>
          </div>
          <div class="part justify">
            <div class="divborder">
              <p>{{ $t("Informations dossiers") }}</p>
            </div>
            <div class="left">
              <ul>
                <h2 class="textstyle">{{ dossierDetail.name }}</h2>
                <li>
                  <span class="title">{{ $t("CUSTOMER") }}:</span>
                  <span class="textstyle">
                    {{ dossierDetail.client.nom }}
                    {{ dossierDetail.client.prenom }}
                  </span>
                </li>
                <li>
                  <span class="title">{{ $t("DELEGATAIRE") }}:</span>
                  <span class="textstyle">
                    {{
                      dossierDetail.delegataire
                        ? dossierDetail.delegataire.name
                        : "-"
                    }}
                  </span>
                </li>
                <li>
                  <span class="title">{{ $t("DESC") }}:</span>
                  <span class="textstyle">
                    {{ dossierDetail.description }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="part justify">
            <div class="divborder">
              <p>{{ $t("DOCUMENT") }}</p>
            </div>
          </div>
          <template v-if="dossierDetail">
            <div class="table-rapport-style w-100">
              <table>
                <thead>
                  <tr>
                    <th style="border-radius: 5px 0px 0px 0px">
                      {{ $t("TYPE") }}
                    </th>
                    <th>
                      {{ $t("DOWNLOAD") }}
                    </th>
                    <th>
                      {{ $t("STATUT") }}
                    </th>
                    <th>{{ $t("ACTION") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    role="row"
                    class="ligneNormale"
                    v-for="(doc, i) in getOcrFileType"
                    :key="i"
                  >
                    <td>{{ doc.name }}</td>
                    <td>
                      <div class="grey">
                        <b-spinner
                          label="Spinning"
                          v-if="fileLoading[i]"
                        ></b-spinner>
                        <svg
                          v-else-if="doc.file"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 20.944 20.944"
                          @click="downloadFile(doc, i)"
                        >
                          <g
                            id="Icon_feather-download"
                            data-name="Icon feather-download"
                            transform="translate(-3.5 -3.5)"
                          >
                            <path
                              id="Tracé_78100"
                              data-name="Tracé 78100"
                              d="M23.444,22.5v4.21a2.1,2.1,0,0,1-2.1,2.1H6.6a2.1,2.1,0,0,1-2.1-2.1V22.5"
                              transform="translate(0 -5.37)"
                              fill="none"
                              stroke="#28367a"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <path
                              id="Tracé_78101"
                              data-name="Tracé 78101"
                              d="M10.5,15l5.262,5.262L21.025,15"
                              transform="translate(-1.79 -3.133)"
                              fill="none"
                              stroke="#28367a"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <path
                              id="Tracé_78102"
                              data-name="Tracé 78102"
                              d="M18,17.13V4.5"
                              transform="translate(-4.028)"
                              fill="none"
                              stroke="#28367a"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </g>
                        </svg>
                        <font-awesome-icon icon="times" v-else />
                      </div>
                    </td>

                    <td aria-colindex="5" role="cell">
                      <span
                        class="end"
                        style="background: #3dd598"
                        v-if="doc.file && doc.file.status != 'empty'"
                      >
                        {{ $t("Envoyé") }}
                      </span>
                    </td>
                    <td v-if="!dossierDetail.lock">
                      <b-spinner
                        label="Spinning"
                        v-if="fileLoading[i]"
                      ></b-spinner>
                      <b-button
                        size="sm"
                        variant="danger"
                        v-else-if="doc.file && !fileLoading[i]"
                        @click="handleDeleteFile(i)"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                      <label
                        class="btn btn-primary btn-sm"
                        :for="`file_input` + i"
                        v-else-if="doc.name != 'Devis' && doc.name != 'Facture'"
                      >
                        <font-awesome-icon icon="plus" />
                      </label>
                      <label
                        v-else
                        class="btn btn-primary btn-sm"
                        @click="openUpload(doc)"
                      >
                        <font-awesome-icon icon="plus" />
                      </label>

                      <b-form-file
                        :id="`file_input` + i"
                        style="display: none"
                        @change="handleUpload($event, i)"
                      ></b-form-file>
                    </td>
                    <td v-else>
                      <b-button size="sm" variant="success" disabled>
                        <font-awesome-icon icon="plus" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>

        <template #modal-footer>
          <div class="double">
            <b-button variant="danger" @click="resetModal()">
              <div class="block-spinner">
                {{ $t("CLOSE") }}
              </div>
            </b-button>

            <b-button
              variant="success"
              @click="handleSend"
              v-if="!dossierDetail.lock && dossierDetail.is_ready"
            >
              <div class="block-spinner">
                {{ $t("CONFIRM") }}
                <div v-if="getLoadingdossier" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>

            <b-button
              variant="success"
              @click="handleSend"
              v-if="!dossierDetail.lock && !dossierDetail.is_ready"
              disabled
            >
              <div class="block-spinner">
                {{ $t("CONFIRM") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-modal
        id="ModelConfigDossier"
        ref="ModelConfigDossier"
        hide-footer
        @hide="v$.$reset()"
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }} {{ $t("Dossiers") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <form v-if="dossierToConfig">
          <div class="center">
            <b-form-group :label="$t('NAME') + '*'" label-for="name">
              <b-form-input
                id="name"
                v-model="v$.dossierToConfig.name.$model"
                :state="validateState('name')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="name-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.dossierToConfig.name.$errors"
                id="name-feedback"
              ></error-handle>
              <div v-if="erreurlist.name" class="error-message">
                <ul v-if="Array.isArray(erreurlist.name)">
                  <span v-for="(e, index) in erreurlist.name" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.name }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('Description')">
              <b-form-textarea
                v-model="v$.dossierToConfig.description.$model"
                :state="validateState('description')"
                :placeholder="$t('Description')"
                maxlength="1000"
                rows="3"
                required
              ></b-form-textarea>
              <error-handle
                :list="v$.dossierToConfig.description.$errors"
                id="description-feedback"
              ></error-handle>
              <div v-if="erreurlist.description" class="error-message">
                <ul v-if="Array.isArray(erreurlist.description)">
                  <span
                    v-for="(e, index) in erreurlist.description"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.description }}</span>
              </div>
            </b-form-group>
          </div>

          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorU" class="messageError">
              {{ errorU }}
            </b-alert>
            <b-button variant="success" @click="EditDossier">
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getLoadingdossier" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>

      <b-modal id="uploadDocument" ref="uploadDocument" hide-footer>
        <template #modal-header="{ close }">
          <h5 v-if="document.type && document.type.name == 'Devis'">
            {{ $t("Upload") }} {{ $t("ESTIMATE") }}
          </h5>
          <h5 v-else>{{ $t("Upload") }} {{ $t("INVOICE") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <form
          @submit.prevent="uploadDocument(tabIndex)"
          v-if="dossierDetail && document"
        >
          <b-tabs content-class="tabs" justified v-model="tabIndex">
            <b-tab :title="$t('Document existant')" active lazy>
              <div class="center">
                <b-form-group
                  :label="$t('ESTIMATE')"
                  v-if="document.type && document.type.name == 'Devis'"
                >
                  <searchInput
                    :list="computedDevis"
                    :loader="getdevisLoading"
                    label="numerodevis"
                    :searchFunc="devis"
                    @searchfilter="document.doc = $event"
                    :filtre="{
                      client: dossierDetail.client_id,
                    }"
                  ></searchInput>
                </b-form-group>

                <b-form-group :label="$t('INVOICE')" v-else>
                  <searchInput
                    :list="computedFacture"
                    :loader="getFactureLoading"
                    label="numerofactures"
                    :searchFunc="factures"
                    @searchfilter="document.doc = $event"
                    :filtre="{
                      client: dossierDetail.client_id,
                    }"
                  ></searchInput>
                </b-form-group>
              </div>
            </b-tab>

            <b-tab :title="$t('Depuis vos fichiers')" active lazy>
              <div class="center">
                <b-form-group
                  :label="$t('ESTIMATE')"
                  v-if="document.type && document.type.name == 'Devis'"
                >
                  <b-form-file
                    v-model="document.file"
                    :placeholder="$t('CHOOSE')"
                    plain
                    :required="tabIndex == 1"
                  ></b-form-file>
                </b-form-group>
                <b-form-group :label="$t('INVOICE')" v-else>
                  <b-form-file
                    v-model="document.file"
                    :placeholder="$t('CHOOSE')"
                    plain
                    :required="tabIndex == 1"
                  ></b-form-file>
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
          <div class="actionModel">
            <b-button variant="success" type="submit">
              <div class="block-spinner">
                {{ $t("SAVE") }}
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>

      <b-modal size="lg" centered id="ModelError" ref="ModelError">
        <template #modal-header="{ close }">
          <h5>{{ $t("ERROR") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#fff"
              />
            </svg>
          </b-button>
        </template>
        <div class="messageError" v-if="erreur">{{ erreur }}</div>
        <template #modal-footer>
          <div class="actionModel">
            <b-button variant="danger" @click="hideModal('ModelError')">
              <div class="block-spinner">
                {{ $t("CLOSE") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <deleteModalVue
        :loader="getLoadingdossier"
        :elemToDelete="dossierToDelete"
        :elemDelete="delete_dossier"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowDossier"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowDossier"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getLoadingdossier"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/dossiers"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/dossiermodale.vue";
import errorHandle from "../components/ui/errorHandle.vue";
import CommentMarche from "../components/commentMarche.vue";
import moment from "moment";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    deleteModalVue,
    storeModalVue,
    errorHandle,
    CommentMarche,
    searchInput: () => import("@/components/ui/searchInput"),
    searchBar: () => import("../components/ui/searchBar.vue"),
  },
  data() {
    return {
      search: "",
      dossierToConfig: null,
      dossierToDelete: null,
      dossierDetail: null,
      downloadLoader: false,
      page: 1,
      perPage: 10,
      errorU: false,
      fileLoading: [],
      current_client: {
        id: null,
      },
      erreurlist: {
        name: null,
        client_id: null,
      },
      filtre_client: {
        id: null,
      },
      document: {
        type: null,
        file: null,
        doc: null,
      },
      tabIndex: 0,
      erreur: null,
    };
  },
  validations() {
    return {
      dossierToConfig: {
        name: { required, maxLength: maxLength(100) },
        description: { maxLength: maxLength(1000) },
      },
    };
  },
  methods: {
    ...mapActions([
      "alldossier",
      "edit_dossier",
      "delete_dossier",
      "OcrFileTypes",
      "all_clients",
      "upload_file",
      "delete_file",
      "allfiles",
      "devis",
      "factures",
      "download_file",
      "send_dossier",
      "one_dossier",
    ]),

    moment: function (date) {
      return moment(new Date(date));
    },

    validateState(name) {
      const { $dirty, $error } = this.v$.dossierToConfig[name];
      return $dirty ? !$error : null;
    },

    openUpload(type) {
      if (type.name == "Devis") {
        this.devis({
          page: 1,
          per_page: 1000,
          client: this.dossierDetail.client_id,
        });
      } else {
        this.factures({
          page: 1,
          per_page: 1000,
          client: this.dossierDetail.client_id,
        });
      }

      this.document.type = type;
      this.$root.$emit("bv::show::modal", "uploadDocument");
    },

    downloadFile(doc, key) {
      this.$set(this.fileLoading, key, true);

      this.download_file(doc.file.id)
        .then((file) => {
          // Crée un lien de téléchargement temporaire
          const url = window.URL.createObjectURL(new Blob([file]));

          // Crée un élément d'ancrage <a> pour démarrer le téléchargement
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${doc.name}.pdf`); // Définit le nom du fichier à télécharger

          // Simule un clic sur le lien pour démarrer le téléchargement

          this.$set(this.fileLoading, key, false);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$set(this.fileLoading, key, false);
        });
    },

    selectentreprise(query) {
      if (query) {
        this.filtre_client = query;
      } else {
        this.filtre_client = {
          lib: null,
        };
      }
      this.setup(true);
    },

    async uploadDocument(tab) {
      var bodyFormData = new FormData();
      bodyFormData.append("ocr_file_type_id", this.document.type.id);
      bodyFormData.append("ocr_dossier_id", this.dossierDetail.id);

      if (tab == 0) {
        if (!this.document.doc) return;
        bodyFormData.append("batigo_file_id", this.document.doc.id);
      } else {
        bodyFormData.append("file", this.document.file);
      }
      const file = await this.upload_file(bodyFormData);
      this.hideModal("uploadDocument");
      this.getOcrFileType.forEach((type) => {
        if (type.id == file.ocr_file_type_id) type.file = file;
      });
      this.document = {
        type: null,
        file: null,
        doc: null,
      };
      this.dossierDetail = await this.one_dossier(this.dossierDetail.id);
    },

    async handleUpload(event, key) {
      this.$set(this.fileLoading, key, true);
      var bodyFormData = new FormData();
      bodyFormData.append("ocr_file_type_id", this.getOcrFileType[key].id);
      bodyFormData.append("ocr_dossier_id", this.dossierDetail.id);
      bodyFormData.append("file", event.target.files[0]);

      try {
        this.getOcrFileType[key].file = await this.upload_file(bodyFormData);

        this.dossierDetail = await this.one_dossier(this.dossierDetail.id);
        this.$set(this.fileLoading, key, false);
      } catch (err) {
        this.erreur = err;
        this.$set(this.fileLoading, key, false);

        this.$refs["ModelError"].show();
      }
    },

    async handleDeleteFile(key) {
      this.$set(this.fileLoading, key, true);

      this.$bvModal
        .msgBoxConfirm(`${this.$t("DELETE_ACCOUNT_MESSAGE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.deleteFile(key);
          }
        });

      this.$set(this.fileLoading, key, false);
    },

    async deleteFile(key) {
      await this.delete_file(this.getOcrFileType[key].file.id);
      this.getOcrFileType[key].file = null;
      this.dossierDetail = await this.one_dossier(this.dossierDetail.id);
    },

    async searchDossier() {
      await this.alldossier({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleUpdate(CAT) {
      this.dossierToConfig = {
        client: this.getAllclients.find((item) => item.id == CAT.client_id),
        ...CAT,
      };
      this.$refs["ModelConfigDossier"].show();
    },

    handleDelete(CAT) {
      this.dossierToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },

    handleDossier(CAT) {
      this.OcrFileTypes({}).then(() => {
        this.getOcrFileType.forEach((type) => {
          this.fileLoading.push(false);
          type.file = null;
        });
        this.dossierDetail = { ...CAT };
        this.allfiles(CAT.id).then(() => {
          this.fusionFileType();
        });
        this.$root.$emit("bv::show::modal", "detailModal");
      });
    },

    fusionFileType() {
      this.getOcrFiles.forEach((file) => {
        this.getOcrFileType.forEach((type) => {
          if (type.id == file.ocr_file_type_id) {
            type.file = file;
          }
        });
      });
    },

    handleSend() {
      this.send_dossier(this.dossierDetail.id).then(() => {
        this.hideModal("detailModal");
        this.setup();
      });
    },

    resetModal() {
      this.erreurlist = {
        name: null,
        client_id: null,
      };
      this.errorU = null;
      this.setup();
      this.hideModal("ModelConfigDossier");
      this.hideModal("detailModal");
    },

    async EditDossier() {
      const isFormCorrect = await this.v$.dossierToConfig.$error;
      this.erreurlist = {
        name: null,
        client_id: null,
      };
      this.errorU = null;
      if (isFormCorrect) return;
      const data = {
        id: this.dossierToConfig.id,
        name: this.dossierToConfig.name,
        description: this.dossierToConfig.description,
      };

      await this.edit_dossier(data)
        .then(() => {
          this.hideModal("ModelConfigDossier");
          this.errorU = null;
        })
        .catch((err) => {
          this.errorU = err;
          // if (this.isObject(err)) {
          //   for (const [key, value] of Object.entries(err)) {
          //     if (this.erreurlist[key] && !this.erreurlist[key]) {
          //       this.erreurlist[key] = value;
          //     }
          //   }
          // } else {
          //   this.errorU = err;
          // }
        });
    },

    handleStore() {
      this.$root.$emit("bv::show::modal", "dossierModal");
    },
    selectClient(item) {
      this.dossierToConfig.client = item;
    },

    pagination(paginate) {
      this.page = paginate;
      this.searchDossier();
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.current_client = this.filtre_client;

      this.alldossier({});
    },
  },

  mounted() {
    this.setup(true);
  },

  computed: {
    ...mapGetters([
      "getAlldossiers",
      "getLoadingdossier",
      "getTotalRowDossier",
      "getTabs",
      "getOnlineUser",
      "getAllDelegataire",
      "getDelegataireLoading",
      "getAllclients",
      "getclientLoading",
      "getOcrFileType",
      "getOcrFiles",
      "getAlldevis",
      "getdevisLoading",
      "getFactureLoading",
      "getAllFacture",
    ]),
    computedDevis() {
      return this.getAlldevis.map((item) => item.devis);
    },
    computedFacture() {
      return this.getAllFacture.map((item) => item.facture);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group .icon-balance {
  top: 8px;
}

.prodimg {
  padding: 8px;
  background: #465081;
  max-width: 64px;
  max-height: 64px;
}
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;
  flex-flow: column;
  align-items: center;
  .messageError {
    color: #e4261b;
    font-size: 16px;
    background-color: #fff;
    border-color: #fff;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.detailClient {
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;

  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    & .prodimg {
      max-width: 185px;
      max-height: 231px;
    }
  }

  & h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  & p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}
.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.image-conteneur {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .block {
    cursor: pointer;
    width: 25%;
    margin-bottom: 16px;
    padding: 15px;
    &:hover {
      background-color: rgba($color: #000, $alpha: 0.1);
    }

    .image-content {
      img {
        width: 100%;
      }
    }

    p {
      margin: 0;
      text-align: center;
      color: #303030;
    }
  }
}

// modal detail
.modal-test {
  width: 95%;
  margin: auto;
}

.cours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  p {
    margin: 0;
    color: #fff;
    background-color: #d0cccc;
    width: fit-content;
    padding: 4px;
    height: 24px;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    font-size: 14px;
  }
}

.divborder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #28367a;
  width: 100%;
  p {
    margin: 0px 10px;
  }
}
.divborder::after,
.divborder::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}

.left {
  ul {
    list-style: none;
  }
  h2 {
    color: #28367a;
    font-weight: 700;
    margin-bottom: 0px;
  }
  .title {
    color: #28367a;
    font-weight: 700;
  }
}

.lock {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: #e4261b;
}
</style>
